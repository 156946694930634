import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, List, useMediaQuery } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '@themes'

const useStyles = makeStyles(theme => ({
  cardNavItems: {
    '&>div': {
      padding: '8px 0px',
    }
  },
}))

const TopNavTabNavItemCards = props => {
  const classes = useStyles(props)
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))

  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box my={2} display="flex" justifyContent="space-between" width="100%">
          {renderBloks(props.blok.cards)}
        </Box>
      ) : (
        <List component="div" disablePadding className={classes.cardNavItems}>
          {renderBloks(props.blok.cards)}
        </List>
      )}
    </SbEditable>
  )
}

export default TopNavTabNavItemCards
